.App {
  height: 100vh;
}

.modeAndTime {
  display: grid;
  grid-template-columns: 240px 1fr 32px;
  gap: 16px;
  margin: 8px;
  align-items: center;
}

.rhsHelp {
  padding: 24px;
}

.github {
  color: black;
  font-style: inherit;
  font-size: 2.4rem;
}
